import { Button, Modal } from "react-bootstrap";
import Transact from "./transact";
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import "./buyModal.css";

function BuyModal({
  handleShow,
  handleCloseBuy,
  show,
  amount,
  setAmount,
  balance,
  max,
}) {
  const [buyToken] = Transact();

  const handle = (e) => {
    if (
      e.target.value === "" ||
      (Number(e.target.value) <= 10 && Number(e.target.value) >= 0)
    ) {
      setAmount(e.target.value);
    }
  };

  const handleMax = () => {
    // console.log("max");
    setAmount(max);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseBuy}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>BUY LPI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>My Account Balance:{Number(balance).toFixed(3)} BNB</p>
          <div className="user-input-container">
            <OutlinedInput
              id="outlined-adornment-weight"
              className="user-input"
              label="BNB"
              type="number"
              variant="outlined"
              endAdornment={<InputAdornment position="end">BNB</InputAdornment>}
              value={amount}
              onChange={(e) => handle(e)}
            />
            <Button
              type="button"
              className="max-button"
              onClick={() => handleMax()}
            >
              MAX
            </Button>
          </div>
          <div className="cancel-confirm-container">
            <Button
              type="button"
              variant="outline-secondary"
              onClick={handleCloseBuy}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => buyToken(amount)}>
              {" "}
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuyModal;
