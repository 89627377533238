import { useState } from "react";
import { utils } from "ethers";
import { presaleContract } from "./constants";

function PresaleData() {
  // const [isLive, setIsLive] = useState();
  const [startTime, setStartTime] = useState(1632290400);
  const [cap, setCap] = useState(utils.parseEther("480000"));
  const [duration, setDuration] = useState(28800);
  const [rate, setRate] = useState();
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [sold, setSold] = useState();
  const [raised, setRaised] = useState();

  async function readOnChainData(setLoading) {
    // const getisLivecd = await presaleContract.isLive();
    const getStartTime = await presaleContract.startTime();
    const getCap = await presaleContract.cap();
    const getDuration = await presaleContract.duration();
    const getMinMax = await presaleContract.getMinMaxLimits();
    const getrate = await presaleContract.rate();
    const getSold = await presaleContract.tokensSold();
    const getRaised = await presaleContract.raised();

    // console.log("live", getisLive);
    // console.log("start", getStartTime.toNumber());
    // console.log("cap", getCap);
    // console.log("duration", getDuration.toNumber());
    // console.log("minmac", utils.formatEther(getMinMax[0]));
    // console.log("max", utils.formatEther(getMinMax[1]));
    // console.log("rate", getrate.toNumber());
    // console.log("functions", presaleContract.functions);
    // console.log("sold", utils.formatEther(getSold));
    // console.log("raised", utils.formatEther(getRaised));

    // setIsLive(getisLive);
    setStartTime(getStartTime.toNumber());
    setCap(utils.formatEther(getCap));
    setDuration(getDuration.toNumber());
    setMin(utils.formatEther(getMinMax[0]));
    setMax(utils.formatEther(getMinMax[1]));
    setRate(getrate.toNumber());
    setSold(utils.formatEther(getSold));
    setRaised(utils.formatEther(getRaised));
    setLoading(false);
  }

  return [
    readOnChainData,
    startTime,
    cap,
    duration,
    rate,
    min,
    max,
    sold,
    raised,
  ];
}

export default PresaleData;
