import React from "react";

import LPIlogo from "./assets/lpilogocolor.png";
import LPIToken from "./assets/LPI.png";
import metamaskLogo from "./assets/wallets/metamask.svg";
import walletconnectLogo from "./assets/wallets/walletconnect.svg";

import "./App.css";
import { Button, ProgressBar } from "react-bootstrap";
import { StyledModal, Backdrop, style } from "./components";
import { Box, CircularProgress } from "@material-ui/core";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { utils } from "ethers";
import { shortenAddress } from "./components/shortenAddress";
import PresaleData from "./components/presale";
import WalletInfo from "./components/walletInfo";
import BuyModal from "./components/buyModal";
// import { rpcProvider } from "./components/constants";

import { useWallet } from "use-wallet";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";

function App() {
  const [
    readOnChainData,
    startTime,
    cap,
    duration,
    rate,
    min,
    max,
    sold,
    raised,
  ] = PresaleData();

  const [readWalletData, whitelisted, purchasedAmount] = WalletInfo();
  const wallet = useWallet();
  const [amount, setAmount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [wloading, setWLoading] = React.useState();
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const cap = 1200000;

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    wallet.reset();
    setOpen(false);
  };

  const handleMetamask = () => {
    wallet.connect();
    // console.log("status", ChainUnsupportedError);
  };

  React.useEffect(() => {
    if (wallet.account) {
      setWLoading(true);
      readWalletData(wallet.account, setWLoading);
      console.log(wallet.connector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.account]);

  // Reload using interval
  React.useEffect(() => {
    const intervalID = setInterval(() => {
      // console.log("reading");
      readOnChainData(setLoading);
    }, 15000);

    return () => clearInterval(intervalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    readOnChainData(setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // Reload using Block Number
  // React.useEffect(() => {
  //   rpcProvider.on("block", (blockNumber) => {
  //     // Emitted on every block change
  //     console.log(blockNumber);
  //     readOnChainData(setLoading);
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    if (wallet.error) {
      toast.error(`${wallet.error}`);
    } else if (wallet.status) {
      toast(`${wallet.status}`);
    }
  }, [wallet.status, wallet.error]);

  const handleBuy = () => {
    handleShow();
  };

  const handleCloseBuy = () => setShow(false);
  const handleShow = () => setShow(true);

  const Completionist = () => <span>Sale has ended!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s{" "}
        </span>
      );
    }
  };

  return (
    <div className="Main">
      <div className="topbar-container">
        <div className="topbar-box-left">
          <img src={LPIlogo} alt="LPI DAO" className="logo" />
        </div>

        <div className="topbar-box-center"></div>

        <div className="topbar-box-right">
          {wallet.status === "connected" ? (
            <div>
              <Button>
                {wallet.account ? shortenAddress(wallet.account) : ""}
              </Button>
              {/* <div>Balance: {wallet.balance}</div> */}
              <Button onClick={() => handleClose()} variant="outline-primary">
                Disconnect
              </Button>
            </div>
          ) : (
            <div>
              <Button onClick={handleOpen} variant="primary">
                Connect
              </Button>
              <StyledModal
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
              >
                <Box className="wallet-options-container" sx={style}>
                  <button
                    className="wallet-option"
                    onClick={() => handleMetamask()}
                  >
                    <img src={metamaskLogo} alt="Metamask logo" />
                    <span>Metamask</span>
                  </button>
                  <button
                    className="wallet-option"
                    onClick={() => wallet.connect("walletconnect")}
                  >
                    <img src={walletconnectLogo} alt="WalletConnect logo" />
                    <span>WalletConnect</span>
                  </button>
                </Box>
              </StyledModal>
            </div>
          )}
        </div>
      </div>

      <div>
        <ToastContainer />

        <div className="flex items-stretch md:space-x-3 flex-col md:flex-row">
          <div className="p-3 md:w-1/3 md:mb-0 bg-gray-50 bg-opacity-90 rounded-lg border-r border-dark shadow-2xl">
            <div className="space-y-6">
              <div className="grid grid-cols-3 items-center">
                <div className="mr-3">
                  <div className="inline-block rounded-full p-1 brand-bg">
                    <div className="h-16 w-16 bg-darkGray rounded-full">
                      <img
                        src={LPIToken}
                        alt="LPI"
                        className="h-full rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <div>
                    <h2 className="text-2xl font-secondary tracking-wide">
                      LPI Token
                    </h2>
                  </div>
                </div>
                <div className="mr-3"></div>
              </div>
              <div className="bg-gray-500 bg-opacity-10 p-2 rounded-2xl relative">
                {sold === cap && (
                  <div className="space-y-3">
                    <h2 className="text-lg text-center">
                      {" "}
                      🎉 The sale is sold out! 🎉
                    </h2>
                    <p className="">Thank you for participating!</p>
                    <div className="space-y-3">
                      <div>
                        You have bought:{" "}
                        <span className="text-main ">
                          {purchasedAmount} LPI Tokens
                        </span>
                      </div>
                      <div>
                        Your contribution:{" "}
                        <span className="text-main ">
                          {purchasedAmount / rate} BNB
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {sold !== cap && (
                  <div className="space-y-3">
                    <h2 className="text-lg text-center"> Whitelist Status</h2>

                    {wallet.status === "connected" && !wloading && (
                      <div>
                        {whitelisted && (
                          <div>
                            <p>Congratulations 🥳 You are Whitelisted</p>

                            {startTime * 1000 >= Date.now() && (
                              <Button variant="secondary" disabled>
                                Sale not started yet
                              </Button>
                            )}

                            {startTime * 1000 <= Date.now() &&
                              Date.now() <= (startTime + duration) * 1000 && (
                                <Button variant="primary" onClick={handleBuy}>
                                  BUY NOW
                                </Button>
                              )}

                            {Date.now() > (startTime + duration) * 1000 && (
                              <Button variant="secondary" disabled>
                                Round has ended.
                              </Button>
                            )}
                          </div>
                        )}

                        {!whitelisted && (
                          <p>'Sorry, You didnt make it for this round'</p>
                        )}
                      </div>
                    )}

                    {(wloading || wallet.status !== "connected") && (
                      <Button onClick={handleOpen} variant="primary">
                        {!wloading
                          ? "Connect Wallet to Check Status"
                          : "Loading..."}
                      </Button>
                    )}
                  </div>
                )}

                <BuyModal
                  handleShow={handleShow}
                  handleCloseBuy={handleCloseBuy}
                  show={show}
                  amount={amount}
                  setAmount={setAmount}
                  balance={utils.formatEther(wallet.balance)}
                  max={max}
                />
              </div>

              <div className="space-y-10">
                <div>
                  <div className="text-2xl brand-text">1 BNB = {rate} LPI</div>
                  <div className="text-l brand-text">
                    You have purchased {purchasedAmount} LPI{" "}
                  </div>
                </div>

                <h2 className="text-lg mb-2 font-secondary tracking-wider text-primary">
                  Token Information
                </h2>
                <ul>
                  <li className="flex py-1 justify-between md:justify-start items-center">
                    <div className="font-semibold mr-3">Token:</div>
                    <div className="">
                      <span className="text-main ">LPI Token (LPI)</span>
                    </div>
                  </li>
                  <li className="flex py-1 justify-between md:justify-start items-center">
                    <div className="font-semibold mr-3">Type:</div>
                    <div className="">BEP20(BSC)</div>
                  </li>
                  <li className="flex py-1 justify-between md:justify-start items-center">
                    <div className="font-semibold mr-3">Total Supply:</div>
                    <div className="">10,000,000</div>
                  </li>
                  <li>
                    <div className="flex items-center space-x-3 py-1">
                      <span className="font-semibold">Address BSC: TBA</span>
                      {/* <a href="#" className="break-all no-underline text-white"><span>0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C</span></a><a href="https://bscscan.com/address/0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C" target="_blank" className="flex-shrink-0 "></a> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-3 md:w-2/3 bg-gray-50 bg-opacity-90 rounded-lg border-r border-dark shadow-lg">
            {loading ? (
              <Box className="h-full" sx={{ display: "flex" }}>
                <CircularProgress className="m-auto" />
              </Box>
            ) : (
              <div className="p-3 space-y-6">
                <div className="pb-3">
                  <div className="flex items-center justify-center mb-3 flex-wrap">
                    <div className="flex items-center mb-3 md:mb-0">
                      <h2 className="text-2xl font-secondary tracking-wide text-center">
                        LPI DAO WHITELIST SALE
                      </h2>
                    </div>
                    {/* <div className="space-x-3 flex items-center"><img src="/brand/binance_logo.png" alt="Binance Smart Chain" className="md:h-6 h-5"/></div> */}
                  </div>
                  <div className="mb-3">
                    <div>
                      LPI DAO is a community based DeFi seed sale aggregator
                      platform which provides gateway to seed sale for projects
                      specializing in GameFi & NFT.
                    </div>
                  </div>
                </div>
                <div className="space-y-3">
                  <h2 className="text-lg mb-2 font-secondary tracking-wider text-primary">
                    Sale Details
                  </h2>
                  <div className="flex flex-col md:flex-row flex-wrap">
                    <div className="md:w-1/2">
                      <ul>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">Access:</div>
                          <div className="">Whitelisted</div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">Hard Cap:</div>
                          <div className="">{cap / rate} BNB</div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">Swap Rate:</div>
                          <div className="">
                            <span className="text-main ">{rate} LPI</span> per{" "}
                            <span className="text-main ">BNB</span>
                          </div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">
                            Listing Price:
                          </div>
                          <div className="">
                            <span className="text-main ">$0.50</span>
                          </div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">Sale Price:</div>
                          <div className="">
                            <span className="text-main ">$0.25</span>
                          </div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">
                            Min. Contribution:
                          </div>
                          <div className="">
                            <span className="text-main ">{min} BNB</span>
                          </div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">
                            Max. Contribution:
                          </div>
                          <div className="">
                            <span className="text-main ">{max} BNB</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="md:w-1/2">
                      <ul>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">Start:</div>
                          <div className="">22 September, 06:00 am UTC</div>
                        </li>
                        <li className="flex py-1 justify-between md:justify-start items-center">
                          <div className="font-semibold mr-3">End:</div>
                          <div className="">22 September, 14:00 pm UTC</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="text-lg mb-2 font-secondary tracking-wider text-primary">
                    Status
                  </h2>

                  <div className="space-y-1">
                    <div className="flex justify-between text-sm tracking-wide space-x-3">
                      <div className="">
                        {startTime * 1000 > Date.now() && (
                          <span className="text-lg font-bold">
                            Sale starts in:{" "}
                            <Countdown
                              date={startTime * 1000}
                              renderer={renderer}
                            />
                          </span>
                        )}
                        {startTime * 1000 <= Date.now() &&
                          Date.now() <= (startTime + duration) * 1000 && (
                            <span className="text-lg font-bold">
                              Sale ends in:{" "}
                              <Countdown
                                date={(startTime + duration) * 1000}
                                renderer={renderer}
                              />
                            </span>
                          )}

                        {Date.now() > (startTime + duration) * 1000 && (
                          <span className="text-lg font-bold">
                            Round has ended{" "}
                          </span>
                        )}
                      </div>
                      <div className="font-medium opacity-90 text-right">
                        <span className="text-main ">
                          {Math.round((sold / cap) * 100)}% Completed
                        </span>
                      </div>
                    </div>
                    <div className="h-2 bg-gray-600 m-2 ">
                      <ProgressBar animated now={(sold / cap) * 100} />
                    </div>
                    <div className="flex justify-between text-sm tracking-wide space-x-3">
                      <div className="text-ignored opacity-90 flex-shrink-0">
                        {raised} BNB
                      </div>
                      <div className="text-ignored opacity-90 text-right">
                        {sold} / {cap} LPI
                      </div>
                    </div>
                  </div>
                  <h2 className="text-lg mb-2 font-secondary tracking-wider text-primary">
                    Socials
                  </h2>
                  <div className="space-x-6 flex items-center">
                    <ul className="flex items-center text-main space-x-6 text-2xl mx-auto pl-0">
                      <li>
                        <a
                          href="https://t.me/LpiDaoOfficial"
                          rel="noreferrer"
                          target="_blank"
                          className="text-main"
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 448 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/LpiDao"
                          rel="noreferrer"
                          target="_blank"
                          className="text-main"
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://lpi-dao.medium.com/"
                          rel="noreferrer"
                          target="_blank"
                          className="text-main"
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 448 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://lpi.finance"
                          rel="noreferrer"
                          target="_blank"
                          className="text-main"
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 496 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
}

// export default () => (
//   <UseWalletProvider
//     chainId={3}
//     connectors={{
//       // This is how connectors get configured
//       walletconnect: {
//         rpcUrl:
//           "https://speedy-nodes-nyc.moralis.io/999d0557d237876cf873c069/eth/ropsten",
//       },
//     }}
//   >
//     <App />
//   </UseWalletProvider>
// );

export default App;
