import { useState } from "react";

import { utils } from "ethers";
import { presaleContract } from "./constants";

function WalletInfo() {
  const [whitelisted, setWhitelisted] = useState();
  const [purchasedAmount, setPurchasedAmount] = useState(0);

  async function readWalletData(address, setWLoading) {
    const whitelisted = await presaleContract.whitelisted(address);
    const purchasedLPI = await presaleContract.balanceOf(address);

    // console.log("woorking", whitelisted);
    setWhitelisted(whitelisted);
    setPurchasedAmount(utils.formatEther(purchasedLPI));
    setWLoading(false);
  }

  return [readWalletData, whitelisted, purchasedAmount];
}

export default WalletInfo;
