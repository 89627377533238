import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";

import { addresses, abis } from "../contracts";

// export const rpcProvider = new ethers.providers.JsonRpcProvider(
//   "https://speedy-nodes-nyc.moralis.io/999d0557d237876cf873c069/eth/ropsten"
// );

export const rpcProvider = new ethers.providers.JsonRpcProvider(
  "https://bsc-dataseed.binance.org/"
);

export const presaleContract = new Contract(
  addresses.presale,
  abis.presale,
  rpcProvider
);

export const explorerLink = "https://bscscan.com/tx/";
